img {
  border-radius: 4px;
  padding: 6px;
  width: 230px;
}

#app-bar {
  position: "absolute";
  z-index: 10;
}
